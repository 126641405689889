<template>
  <footer class="footer-wrapper">
    <cb-container>
      <div class="footer-inner">
        <div
          v-if="showServiceWrapper"
          class="service-wrapper"
        >
          <div
            class="service"
            v-for="(service, index) in services"
            :key="index"
          >
            <component
              class="logo"
              :is="service.logo"
            />
            <div class="text">
              {{ service.description }}
            </div>
            <NuxtLink
              class="link"
              :to="service.link"
            >
              → {{ service.name }}を使ってみる
            </NuxtLink>
          </div>
        </div>

        <ul
          class="navigation-wrapper"
          :class="[{ 'no-top-margin': !showServiceWrapper }]"
        >
          <li
            v-for="(nav, index) in navs"
            :key="index"
          >
            <nuxt-link
              :to="nav.path"
              class="link"
            >
              {{ nav.name }}
            </nuxt-link>
          </li>
        </ul>

        <p class="copyright">
          ©2024 CGBANK All Rights Reserved.
        </p>
      </div>
    </cb-container>
  </footer>
</template>

<script setup lang="ts">
const { showServiceWrapper } = defineProps({
  showServiceWrapper: {
    type: Boolean,
    default: true,
  },
})
const config = useRuntimeConfig()
const services = [
  {
    name: 'MyRenderer',
    logo: 'my-renderer-logo',
    description: '空間デザインの新常識「MyRenderer」があなたのイメージを形にします。ラフスケッチや写真、CGパースなどの画像をアップロードし、スタイルを選択するだけで、AIが質の高いデザイン案を自動生成。デザインの初期段階からクライアントとのイメージ共有まで、効率的に対応可能です。',
    link: config.public.my_renderer_base_url,
  },
  {
    name: 'CGBANK',
    logo: 'cgbank-logo',
    description: '「CGBank」は、建築やインテリアの設計者様向けに、多様な空間画像を提供するサービスです。商用利用可能な空間のデザイン画像を豊富に取り揃え、あらゆるプロジェクトのクリエイティブなアイデアをサポートします。',
    link: config.public.cgbank_base_url,

  },
]

const navs = [
  {
    name: '運営会社情報',
    path: 'https://cgworks.jp/company',
  },
  {
    name: 'プライバシーポリシー',
    path: '/cgbank/privacy',
  },
  {
    name: '利用規約',
    path: '/cgbank/term',
  },
  {
    name: '特定商取引法に基づく表記',
    path: '/cgbank/sctl',
  },
  {
    name: '解約について',
    path: 'https://docs.google.com/forms/d/e/1FAIpQLSe89po31r6a2BWewbnPvxh-HAVnlK14Qd3HtEwLenHgmjxxlA/viewform',
  },
]
</script>

<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  color: var(--white);
  background-color: var(--secondary);
}

.footer-inner {
  padding-bottom: $body-m;
  padding-top: clamp(2.5rem, 1.0555rem + 6.1633vw, 5rem);

  > .service-wrapper {
    gap: 3.75rem;
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    > .service {
      display: flex;
      flex-direction: column;
      width: calc((100% - 3.75rem) / 2);

      @media (max-width: 768px) {
        width: 100%;
      }

      > .logo {
        width: fit-content;
        height: clamp(1.75rem, 1.3166rem + 1.849vw, 2.5rem);
        margin-bottom: 1.875rem;
      }

      > .text {
        font-size: $body-s;
        margin-bottom: 1rem;
        min-height: 120px;
      }
      > .link {
        text-decoration: underline;
        cursor: pointer;
        text-underline-offset: 4px;

        &:hover {
          color: #ed6484;
        }
      }
    }
  }

  > .navigation-wrapper {
    column-gap: 1rem;
    row-gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: clamp(3.75rem, -2.7504rem + 27.735vw, 15rem);

    &.no-top-margin {
      margin-top: 0;
    }

    > li > .link {
      display: block;
      color: var(--white);
      font-size: 0.685rem;
    }
  }

  > .copyright {
    text-align: center;
    margin-top: 14px;
    color: #737584;
    font-family: $english;
    font-weight: 400;
    font-size: 0.685rem;
  }
}
</style>
